<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/login" text=""></ion-back-button>
        </ion-buttons>
        <ion-title>
          <ion-img
            src="assets/logo-header-toolbar.svg"
            class="img-toolbar"
          ></ion-img>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" fullscreen="true">
      <form @submit.prevent="submit" class="max-width-mobile">
        <h2 style="font-weight: 500">{{ $t("Recover Password") }}</h2>
        <ion-item>
          <ion-label position="floating">{{ $t("E-mail") }}</ion-label>
          <ion-input
            type="email"
            v-model="form.email"
            inputmode="email"
            ref="emailInput"
          ></ion-input>
        </ion-item>
        <ion-chip
          v-if="errorMessage"
          color="danger"
          style="width:calc(100% - var(--padding-inline-end, 12px));"
        >
          <ion-label v-t="errorMessage" class="ion-text-center"></ion-label>
        </ion-chip>
        <ion-button type="submit" expand="block" size="medium">{{
          $t("Request link to recover password")
        }}</ion-button>
        <hr />
        <p class="ion-text-center">
          <router-link to="/login">{{
            $t("I remember my password. Go back to login.")
          }}</router-link>
        </p>
      </form>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonImg,
  IonItem,
  IonInput,
  IonLabel,
  IonChip,
  alertController
} from "@ionic/vue";
import axios from "@/plugins/axios";
import { reactive, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  name: "ForgotPassword",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonImg,
    IonItem,
    IonInput,
    IonLabel,
    IonChip
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const form = reactive({
      email: null
    });
    const emailInput = ref(null);
    const errorMessage = ref(false);
    const submit = async () => {
      errorMessage.value = "";
      try {
        const request = await axios.post("/forgot-password", form);
        const alert = await alertController.create({
          cssClass: "error-alert",
          header: t("Recover Password"),
          message: t(request.data.message),
          buttons: [
            {
              text: t("Ok"),
              handler: () => router.push({ path: "/login", replace: true })
            }
          ]
        });
        alert.present();
      } catch (e) {
        console.log(e);
        if (e.response && e.response.data && e.response.data.errors) {
          let errors = [];
          for (let error_type in e.response.data.errors) {
            e.response.data.errors[error_type].forEach(error => {
              errors.push(error);
            });
          }
          if (errors.length) {
            errorMessage.value = errors.join("<br>");
          } else {
            errorMessage.value = "There's been an error";
          }
        } else {
          errorMessage.value = "There's been an error";
        }
        form.email = "";
        await nextTick();
        emailInput.value.$el.focus();
      }
    };

    return {
      form,
      submit,
      router,
      errorMessage,
      emailInput
    };
  }
};
</script>
<style scoped>
ion-item + ion-button,
ion-chip + ion-button {
  margin-top: 10px;
}
ion-chip ion-label {
  width: 100%;
}
hr {
  display: block;
  width: 100%;
  margin: 2em 0;
  border: 0;
  background: var(--ion-color-medium-tint);
  opacity: 0.8;
}
</style>
